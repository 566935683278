/* eslint-env es6*/
var loadTime = 500;
$("html").addClass("loading");
function showPopup() {
    $(".item.pop .inner").on("click", function () {
        $(this).next('.popup').niceScroll({
            scrollspeed: 80,
            mousescrollstep: 30,
            cursorwidth: 5,
            cursorborder: 0,
            cursorcolor: 'rgba(255,255,255,0)',
            cursorborderradius: 2,
            horizrailenabled: false,
            preservenativescrolling: "false",
            bouncescroll: true,
            hwacceleration: true,
            autohidemode: true,
            cursoropacitymax: 1
        });
        $(".item.pop").removeClass('popupOpen');
        $(this).parent('.item').addClass('popupOpen');
    });
    $(".item.pop .closePopup").on("click", function () {
        $(".item.pop").removeClass('popupOpen');
    });
    $(".item.pop").on("mouseleave", function () {
        $(this).removeClass('popupOpen');
    });
}

// Content Areas View - END

function imageSlider() {
    setTimeout(function () {
        $(".project .imgSlider").not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            touchThreshold: 15,
            nextArrow: '<div class="analog-icon arrow_up slick-next"></div>',
            prevArrow: '<div class="analog-icon arrow_up slick-prev"></div>',
            arrows: true,
            fade: false,
            dots: true,
            touchMove: true,
            swipe: true,
            adaptiveHeight: false,
            lazyLoad: 'ondemand'
        });

    }, loadTime);
}

function loadScrollScreenshots() {
    scrollScreenSrcWeb = $(".mp.ipad figure img").attr('data-src');
    scrollScreenSrcMobile = $(".mp.iphone figure img").attr('data-src');
    $(".mp.ipad figure img").attr('src', scrollScreenSrcWeb);
    $(".mp.iphone figure img").attr('src', scrollScreenSrcMobile);
}


function checkWindowSize() {
    var $window = $(window);
    var windowWidth = $window.width();

    // Resize Event
    $window.resize(function () {
        if ($window.width() != windowWidth) {
            windowWidth = $window.width();
            $("html").addClass("resizing");
            hideNavigations();
            setTimeout(function () {
                $("html").removeClass("resizing");
            }, 1000);

        }
    });
}

function detectTouchDevice() {
    var isTouch = ('ontouchstart' in document.documentElement);
    if (isTouch) {
        $('html').addClass('touch');
    } else {
        $('html').addClass('no-touch');
        // customCursor();
    }
}

function detectScrollDir() {
    $('body').addClass("atTop");
    var position = $(window).scrollTop();
    document.addEventListener('scroll', function () {
        var scroll = $(window).scrollTop();
        if (scroll > position) {
            scrollUp = false;
            scrollDown = true;
            $('body').removeClass('scrollingUp').addClass('scrollingDown');
        } else {
            scrollUp = true;
            scrollDown = false;
            $('body').removeClass('scrollingDown').addClass('scrollingUp');
        }
        if (scroll <= 10) {
            scrollUp = false;
            scrollDown = false;
            $('body').removeClass('scrollingUp').removeClass('scrollingDown');
        }
        if (scroll >= 300) {
            $('body').removeClass('atTop');
        } else {
            $('body').addClass('atTop');
        }
        position = scroll;

        var scrollHeight = $(document).height();
        var scrollPosition = $(window).height() + $(window).scrollTop();
        var distToBottom = (scrollHeight - scrollPosition);
        if (distToBottom / scrollHeight === 0) {
            $('body').addClass('atBottom');
        } else {
            $('body').removeClass('atBottom');
        }
    });
}

function scrollspy() {
    var $spyClasses = 'h1, h2, .h2, h3, .h3, p, .news--text';
    UIkit.scrollspy($spyClasses, {});
    UIkit.util.on($spyClasses, 'inview', function () {
        $(this).addClass("appeared");
    });
    UIkit.util.on('h1, h2', 'inview', function () {
        $(this).children().not('br').shuffleLetters().addClass("shuffled");
    });
    UIkit.scrollspy('.referenzen, .leistungen_icons, .logoBadges', {
        target: '.item',
        delay: 100
    });

    UIkit.parallax('.mp.iphone', {
        y: "-100vh"
    });
}

/*
function customCursor() {
    $("body").on("mouseleave", function () {
        $(".cursor").addClass("hidden").removeClass("click, link-hover");
    });
    $("body").on("mouseenter", function () {
        $(".cursor").removeClass("hidden");
    });
    $(document).on("mousemove mouseenter", "body", function (e) {
        var mouseX = e.clientX;
        var mouseY = e.clientY;
        var newposX = mouseX - 60;
        var newposY = mouseY - 60;

        $(".cursor").css(
            "transform",
            "translate3d(" + newposX + "px," + newposY + "px,0px)"
        );
        $("html").css("cursor", "none");
    });
    var $linkClasses = 'a, a span, .btn, .curs, .item.pop, .burger, .closePopup';
    $(document).on("mousedown", "body", function () {
        $(".cursor").addClass("click");
    });
    $(document).on("mouseup", "body", function () {
        $(".cursor").removeClass("click");
    });
    $(document).on("mouseenter", $linkClasses, function () {
        $(".cursor").addClass("link-hover");
    });
    $(document).on("mouseenter", $linkClasses, function () {
        $(".tunnel, section, header").removeClass("link-hover click");
    });
    $(document).on("mouseleave", $linkClasses, function () {
        $(".cursor").removeClass("link-hover");
    });
}
*/

function linkFade() {
    $("a").not("[target='_blank'],[role='button'], [href^=\'tel:\'], [href^=\'mailto:\'], [href^=\'javascript:\']").not('.open-in-lightbox').on('click', function (e) {
        $("html").addClass("loading");
        if ($(this).attr('href')) {
            var url = $(this).attr('href');
            if (url.indexOf("#") > -1) {
                return true;
            }
            e.preventDefault();
            $(".mask").fadeIn(500);
            setTimeout(function () {
                window.location.href = url;
            }, 500, $(".mask").fadeOut(500)), $("html").removeClass("loading"), $('html, body').animate({scrollTop: '0px'}, 0);
        }
    });
}

function shuffleLink() {
    $('a').not('.no-shuffle').on("mouseenter", function () {
        var $elem = $('span:last-child, .linkShuffle', this);
        var childHeight = $elem.height();
        $elem.css("height", childHeight).shuffleLetters({
            "step": 20,
            "fps": 50
        });
    });
    $('a').not('.no-shuffle').on("mouseleave", function () {
        $('span:last-child, .linkShuffle', this).removeAttr('style');
    });
}

function firstScroll() {
    // console.log(document.body.scrollTop);
    $(window).on('scroll', function () {
        $('.startanchor').click();
        $('.mp.iphone').addClass('noTransition');
        $(window).off('scroll');
    });
}

const prefetch = () => {
    const links = $('a')
    for (const link of links) {
        // eslint-disable-next-line no-loop-func
        $(link).mouseenter(() => {
            const $href = $(link).attr('href')
            const linkElement = document.createElement('link');
            linkElement.href = $href;
            linkElement.rel = 'prefetch prerender';
            document.head.appendChild(linkElement);
        })
    }
}

window.addEventListener('load', () => {
    prefetch();
});


function init() {
    detectTouchDevice();
    detectScrollDir();
    linkFade();
    imageSlider();
    checkWindowSize();
    loadScrollScreenshots();
    showPopup();
    scrollspy();
    firstScroll();
    shuffleLink();
}

$(document).ready(function () {
    init();
});

